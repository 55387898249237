<template>
  <div id="userMaterials">
    <header-nav :content="`${$t('pages_router_023')} / ${ $t('pages_router_031')}`" />
    <div class="common-card-body">
      <div class="info-area">
        <div class="title">{{ $t('pages_user_073') }}</div>
        <div class="body">
          <a-form class="user-form" :label-col="{span: 8}" label-align="left">
            <a-form-item :label="$t('pages_user_002')">
              <div class="info-div">{{ userInfo.merchantName }}</div>
            </a-form-item>
            <a-form-item>
              <template #label>
                <span>{{ $t('pages_user_003') }}</span>
              </template>
              <div class="info-div">{{ userInfo.merchantNo }}</div>
            </a-form-item>
          </a-form>
        </div>
      </div>
      <div class="info-area">
        <div class="title">{{ $t('pages_user_001') }}</div>
        <div class="body">
          <a-form class="user-form" :label-col="{span: 8}" label-align="left">
            <a-form-item :label="$t('pages_user_004')" :required="true">
              <div class="info-div">{{ userInfo.email }}</div>
            </a-form-item>
            <a-form-item :label="$t('pages_user_005')" :required="true">
              <div v-if="!mobileStatus" class="info-input flex-align">
                <span>{{ userInfo.mobile }}</span>
                <form-outlined v-if="getUsedCheck(pageLabs, 170101)" class="ic-edit" @click="() => phoneVisible = true" />
              </div>
              <div v-else class="info-div">{{ userInfo.mobile }}</div>
            </a-form-item>
          </a-form>
        </div>
      </div>
      <div class="info-area">
        <div class="title">{{ $t('pages_user_006') }}</div>
        <div class="body">
          <a-form class="user-form" :label-col="{span: 8}" label-align="left">
            <a-form-item v-if="registerInfo.countryCode" :label="$t('pages_user_074')">
              <div class="info-div">{{ registerInfo.countryCode }}</div>
            </a-form-item>
            <a-form-item :label="$t('pages_user_075')">
              <div class="info-div">{{ registerInfo.merchantNameEn }}</div>
            </a-form-item>
            <a-form-item :label="$t('pages_user_076')">
              <div class="info-div">{{ registerInfo.customerIdentity }}</div>
            </a-form-item>
            <a-form-item :label="$t('pages_user_077')">
              <div class="info-div">{{ registerInfo.sourceFunds }}</div>
            </a-form-item>
            <a-form-item :label="$t('pages_user_078')">
              <div class="info-div">{{ registerInfo.paymentPurpose }}</div>
            </a-form-item>
            <a-form-item :label="$t('pages_user_079')">
              <div class="flex-align">
                <div class="info-img">
                  <a-image v-for="(url, index) in registerInfo.businessLicenseUrls" :key="index" :width="100" :src="url" :fallback="require('../../../assets/icons/ic-graphic.png')" @click="openUrl(url)" />
                </div>
                <a-button v-if="updateMap['businessLicensePath']" size="small" danger style="margin-left: 48px" @click="$router.push('/user/cert_update')"><template #icon><sync-outlined /></template>{{ $t('pages_user_134') }}</a-button>
              </div>
            </a-form-item>
            <a-form-item :label="$t('pages_user_080')">
              <div class="info-img">
                <a-image v-for="(url, index) in registerInfo.authorizationUrls" :key="index" :width="100" :src="url" :fallback="require('../../../assets/icons/ic-graphic.png')" @click="openUrl(url)" />
              </div>
            </a-form-item>
            <a-form-item :label="$t('pages_user_081')">
              <div class="flex-align">
                <div class="info-img">
                  <a-image v-for="(url, index) in registerInfo.customerIDUrls" :key="index" :width="100" :src="url" :fallback="require('../../../assets/icons/ic-graphic.png')" @click="openUrl(url)" />
                </div>
                <a-button v-if="updateMap['customerIDPath']" size="small" danger style="margin-left: 48px" @click="$router.push('/user/cert_update')"><template #icon><sync-outlined /></template>{{ $t('pages_user_134') }}</a-button>
              </div>
            </a-form-item>
            <a-form-item :label="$t('pages_user_082')">
              <div class="flex-align">
                <div class="info-img">
                  <a-image v-for="(url, index) in registerInfo.legalUrls" :key="index" :width="100" :src="url" :fallback="require('../../../assets/icons/ic-graphic.png')" @click="openUrl(url)" />
                </div>
                <a-button v-if="updateMap['legalPersonCertificatePath'] || updateMap['directorCertificatePath']" size="small" danger style="margin-left: 48px" @click="$router.push('/user/cert_update')"><template #icon><sync-outlined /></template>{{ $t('pages_user_134') }}</a-button>
              </div>
            </a-form-item>
            <a-form-item :label="$t('pages_user_083')">
              <div class="info-img">
                <a-image v-for="(url, index) in registerInfo.mermorandumUrls" :key="index" :width="100" :src="url" :fallback="require('../../../assets/icons/ic-graphic.png')" @click="openUrl(url)" />
              </div>
            </a-form-item>
            <a-form-item :label="$t('pages_user_084')">
              <div class="flex-align">
                <div class="info-img">
                  <a-image v-for="(url, index) in registerInfo.shareholderUrls" :key="index" :width="100" :src="url" :fallback="require('../../../assets/icons/ic-graphic.png')" @click="openUrl(url)" />
                </div>
                <a-button v-if="updateMap['shareholderCertificatePath']" size="small" danger style="margin-left: 48px" @click="$router.push('/user/cert_update')"><template #icon><sync-outlined /></template>{{ $t('pages_user_134') }}</a-button>
              </div>
            </a-form-item>
            <a-form-item v-if="registerInfo.licensePathOrProofPaymentPath" :label="$t('pages_user_098')">
              <div class="info-img">
                <a-image v-for="(url, index) in registerInfo.licenseUrls" :key="index" :width="100" :src="url" :fallback="require('../../../assets/icons/ic-graphic.png')" @click="openUrl(url)" />
              </div>
            </a-form-item>
            <a-form-item :label="$t('pages_user_085')">
              <div class="info-div">{{ registerInfo.isParentCertificate }}</div>
            </a-form-item>
            <a-form-item v-if="registerInfo.parentCompanines" :label="$t('pages_user_086')">
              <div><div v-for="(item, index) in registerInfo.parentCompanines" :key="index" class="info-div">{{ item.parentCertificateName }} | {{ item.parentCertificateCountry }}</div></div>
            </a-form-item>
            <a-form-item :label="$t('pages_user_087')">
              <div class="info-div">{{ registerInfo.isResides }}</div>
            </a-form-item>
            <a-form-item v-if="registerInfo.subsidiaries" :label="$t('pages_user_088')">
              <div><div v-for="(item, index) in registerInfo.subsidiaries" :key="index" class="info-div">{{ item.residesName }} | {{ item.residesCountry }}</div></div>
            </a-form-item>
            <a-form-item :label="$t('pages_user_089')">
              <div class="info-div">{{ registerInfo.isRiskCountryTransaction }}</div>
            </a-form-item>
            <a-form-item :label="$t('pages_user_090')">
              <div class="info-img">
                <a-image v-for="(url, index) in registerInfo.ownerShipUrls" :key="index" :width="100" :src="url" :fallback="require('../../../assets/icons/ic-graphic.png')" @click="openUrl(url)" />
              </div>
            </a-form-item>
            <a-form-item :label="$t('pages_user_091')">
              <div class="info-img">
                <a-image v-for="(url, index) in registerInfo.nar1Path1Urls" :key="index" :width="100" :src="url" :fallback="require('../../../assets/icons/ic-graphic.png')" @click="openUrl(url)" />
              </div>
            </a-form-item>
            <a-form-item :label="$t('pages_user_092')">
              <div class="info-img">
                <a-image v-for="(url, index) in registerInfo.nnc1PathUrls" :key="index" :width="100" :src="url" :fallback="require('../../../assets/icons/ic-graphic.png')" @click="openUrl(url)" />
              </div>
            </a-form-item>
            <a-form-item :label="$t('pages_user_099')">
              <div class="flex-align">
                <div class="info-img">
                  <a-image v-for="(url, index) in registerInfo.registrationUrls" :key="index" :width="100" :src="url" :fallback="require('../../../assets/icons/ic-graphic.png')" @click="openUrl(url)" />
                </div>
                <a-button v-if="updateMap['businessRegistrationCertificatePath']" size="small" danger style="margin-left: 48px" @click="$router.push('/user/cert_update')"><template #icon><sync-outlined /></template>{{ $t('pages_user_134') }}</a-button>
              </div>
            </a-form-item>
            <a-form-item :label="$t('pages_user_093')">
              <div class="flex-align">
                <div class="info-img">
                  <a-image v-for="(url, index) in registerInfo.incorporationUrls" :key="index" :width="100" :src="url" :fallback="require('../../../assets/icons/ic-graphic.png')" @click="openUrl(url)" />
                </div>
                <a-button v-if="updateMap['incorporationPath']" size="small" danger style="margin-left: 48px" @click="$router.push('/user/cert_update')"><template #icon><sync-outlined /></template>{{ $t('pages_user_134') }}</a-button>
              </div>
            </a-form-item>
            <a-form-item :label="$t('pages_merchant_add_024')">
              <div class="flex-align">
                <div class="info-img">
                  <a-image v-for="(url, index) in registerInfo.businessRegUrls" :key="index" :width="100" :src="url" :fallback="require('../../../assets/icons/ic-graphic.png')" @click="openUrl(url)" />
                </div>
              </div>
            </a-form-item>
            <a-form-item :label="$t('pages_user_094')">
              <div class="info-img">
                <a-image v-for="(url, index) in registerInfo.parentUrls" :key="index" :width="100" :src="url" :fallback="require('../../../assets/icons/ic-graphic.png')" @click="openUrl(url)" />
              </div>
            </a-form-item>
            <a-form-item :label="$t('pages_user_095')">
              <div class="info-input flex-align">
                <span>{{ registerInfo.payerProvince }}</span>
                <form-outlined class="ic-edit" @click="provinceUpdate" />
              </div>
            </a-form-item>
            <a-form-item :label="$t('pages_user_096')">
              <div class="info-input flex-align">
                <span>{{ registerInfo.payerCity }}</span>
                <form-outlined class="ic-edit" @click="cityUpdate" />
              </div>
            </a-form-item>
            <a-form-item :label="$t('pages_user_097')">
              <div class="info-input flex-align">
                <span>{{ registerInfo.payerPostalCode }}</span>
                <form-outlined class="ic-edit" @click="postalCodeUpdate" />
              </div>
            </a-form-item>
          </a-form>
        </div>
      </div>
    </div>
    <a-modal
      :visible="phoneVisible"
      :mask-closable="false"
      :title="$t('pages_user_014')"
      :confirm-loading="phoneLoading"
      @ok="phoneUpdate"
      @cancel="() => phoneVisible = false"
    >
      <a-form ref="phoneRef" :model="phoneForm" :label-col="{span: 5}" label-align="left">
        <a-form-item v-if="userInfo.mobile" :label="$t('pages_user_015')" :required="true">
          <span>{{ userInfo.mobile }}</span>
        </a-form-item>
        <a-form-item
          name="mobile"
          :label="$t('pages_user_016')"
          :rules="[{ required: true, message: $t('pages_user_016') + $t('common_text_042') }]"
        >
          <div class="flex-align">
            <a-form-item-rest>
              <a-select v-model:value="phoneForm.areaCode" style="flex: 1">
                <a-select-option
                  v-for="item in areaCodeList"
                  :key="item"
                  :value="item"
                >+{{ item }}</a-select-option>
              </a-select>
            </a-form-item-rest>
            <a-input
              v-model:value="phoneForm.mobile"
              allow-clear
              style="flex: 3; margin-left: 24px"
              :maxlength="11"
              :placeholder="$t('common_text_006') + $t('pages_user_016')"
            />
          </div>
        </a-form-item>
        <a-form-item
          name="verificationCode"
          :label="$t('pages_user_020')"
          :rules="[{ required: true, message: $t('pages_user_020') + $t('common_text_042') }]"
        >
          <div class="flex-align">
            <a-input
              v-model:value="phoneForm.verificationCode"
              allow-clear
              :placeholder="$t('common_text_006') + $t('pages_user_020')"
            />
            <a-button
              type="primary"
              style="margin-left: 24px"
              :disabled="countNum > 0"
              :loading="codeLoading"
              @click="sendCode"
            >{{ countNum > 0 ? `${countNum}${$t('pages_user_018')}` : $t('pages_user_017') }}</a-button>
          </div>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import HeaderNav from '@/components/header-nav'
import { ref, reactive, toRefs, onUnmounted, createVNode } from 'vue'
import { useStore } from 'vuex'
import { _user, _oss, _account } from '@/api'
import { getUsedCheck, getTradeStatusName, getArrayStatusName } from '@/util'
import { message, notification, Modal, Input } from 'ant-design-vue'
import i18n from '@/locale'
export default {
  name: 'UserMaterials',
  components: {
    'header-nav': HeaderNav
  },
  setup () {
    let timer = ref(null)
    const phoneRef = ref(null)
    const store = useStore()
    const state = reactive({
      model: null,
      userInfo: store.getters.getUserInfo,
      updateMap: {},
      registerInfo: {},
      areaCodeList: [],
      areaCode: '',
      pageLabs: [],
      countNum: 0,
      phoneVisible: false,
      phoneLoading: false,
      codeLoading: false,
      mobileStatus: true,
      language: '',
      phoneForm: {
        areaCode: store.getters.getUserInfo.areaCode,
        mobile: '',
        verificationCode: ''
      }
    })

    onUnmounted(() => {
      clearInterval(timer)
    })

    const openUrl = (url) => {
      const split = url.split('.')
      const splitAfter = split[split.length - 1]
      if (splitAfter && splitAfter.indexOf('jpg') === -1 && splitAfter.indexOf('jepg') === -1 && splitAfter.indexOf('png') === -1 &&
        splitAfter.indexOf('JPG') === -1 && splitAfter.indexOf('JEPG') === -1 && splitAfter.indexOf('PNG') === -1) {
        window.open(url)
      }
    }

    const checkMobile = () => {
      const mobileStatus = localStorage.getItem('mobileStatus')
      state.mobileStatus = mobileStatus
      if (!mobileStatus) {
        notification.warning({
          message: i18n.global.t('pages_user_012'),
          description: i18n.global.t('pages_user_013')
        })
      }
    }

    const phoneUpdate = () => {
      phoneRef.value.validateFields()
        .then(values => {
          state.phoneLoading = true
          const data = Object.assign({}, values, {
            areaCode: state.phoneForm.areaCode,
            language: state.language,
            mobile: state.phoneForm.mobile,
            verificationCode: state.phoneForm.verificationCode
          })
          state.phoneLoading = false
          return _user.updateAcctInfo(data)
        })
        .then(res => {
          if (res.data.succ) {
            store.commit('updateUserInfo', res.data.data)
            localStorage.setItem('mobileStatus', true)
            state.mobileStatus = true
            message.success(res.data.resMsg)
            state.phoneVisible = false
            phoneRef.value.resetFields()
          } else {
            message.error(res.data.resMsg)
          }
        })
        .catch(err => console.log(err))
    }

    const provinceUpdate = () => {
      let inputValue = ''
      state.model = Modal.confirm({
        title: i18n.global.t('pages_user_095') + i18n.global.t('pages_user_027'),
        icon: null,
        content: createVNode(Input, {
          style: { marginTop: '12px' },
          placeholder: i18n.global.t('common_text_006') + i18n.global.t('pages_user_095'),
          onChange: e => { inputValue = e.target.value }
        }),
        async onOk() {
          if (!inputValue) {
            message.error(i18n.global.t('common_text_006') + i18n.global.t('pages_user_095'))
          } else {
            const res = await _user.updateKycInfo({ payerProvince: inputValue })
            if (res.data.succ) {
              message.success(i18n.global.t('common_text_022'))
              loadAcctRegisterInfo()
            }
          }
        }
      })
    }

    const cityUpdate = () => {
      let inputValue = ''
      state.model = Modal.confirm({
        title: i18n.global.t('pages_user_096') + i18n.global.t('pages_user_027'),
        icon: null,
        content: createVNode(Input, {
          style: { marginTop: '12px' },
          placeholder: i18n.global.t('common_text_006') + i18n.global.t('pages_user_096'),
          onChange: e => { inputValue = e.target.value }
        }),
        async onOk() {
          if (!inputValue) {
            message.error(i18n.global.t('common_text_006') + i18n.global.t('pages_user_096'))
          } else if (!(/^\w+$/.test(inputValue))) {
            message.error(i18n.global.t('common_text_046'))
          } else {
            const res = await _user.updateKycInfo({ payerCity: inputValue })
            if (res.data.succ) {
              message.success(i18n.global.t('common_text_022'))
              loadAcctRegisterInfo()
            }
          }
        }
      })
    }

    const postalCodeUpdate = () => {
      let inputValue = ''
      state.model = Modal.confirm({
        title: i18n.global.t('pages_user_097') + i18n.global.t('pages_user_027'),
        icon: null,
        content: createVNode(Input, {
          style: { marginTop: '12px' },
          placeholder: i18n.global.t('common_text_006') + i18n.global.t('pages_user_097'),
          onChange: e => { inputValue = e.target.value }
        }),
        async onOk() {
          if (!inputValue) {
            message.error(i18n.global.t('common_text_006') + i18n.global.t('pages_user_097'))
          } else {
            const res = await _user.updateKycInfo({ payerPostalCode: inputValue })
            if (res.data.succ) {
              message.success(i18n.global.t('common_text_022'))
              loadAcctRegisterInfo()
            }
          }
        }
      })
    }

    const sendCode = async () => {
      state.codeLoading = true
      const res = await _user.getMspVerificationCode({
        mobile: state.phoneForm.mobile,
        areaPhoneCode: 86
      })
      state.codeLoading = false
      if (res.data.succ) {
        state.countNum = 60
        timer = setInterval(() => {
          state.countNum--
          if (state.countNum === 0) clearInterval(timer)
        }, 1000)
      } else {
        message.error(res.data.resMsg)
      }
    }

    const loadChildrenMenu = async () => {
      const res = await _account.getChildrenMenu({ parentId: 1701 })
      if (res.data.succ) {
        state.pageLabs = res.data.data || []
      }
    }

    const loadUpdateMap = async () => {
      const res = await _user.queryCertificateExpired()
      if (res.data.succ) state.updateMap = res.data.data
    }

    const loadSysLanguage = async () => {
      const res = await _user.getSysLanguage()
      if (res.data.succ) {
        const { data } = res.data
        const langList = []

        for (const i in data) {
          langList.push({ label: data[i], value: i })
        }

        const lang = langList.find(item => item.value === state.userInfo.language)

        if (lang) state.language = lang.value
        else state.language = langList[0].value
      }
    }

    const loadPhoneAreaCode = async () => {
      const res = await _user.getPhoneAreaCode()
      if (res.data.succ) {
        const areaCodeList = []
        for (const item of res.data.data) {
          if (item === '86' && !state.phoneForm.areaCode) state.phoneForm.areaCode = item
          areaCodeList.push(item)
        }
        state.areaCodeList = areaCodeList
        if (!state.phoneForm.areaCode) state.phoneForm.areaCode = areaCodeList[0]
        state.areaCode = state.phoneForm.areaCode
      }
    }

    const loadAcctRegisterInfo = async () => {
      const res = await _user.getAcctRegisterInfo()
      if (res.data.succ) {
        const { data } = res.data
        let legalUrls = []
        let shareholderUrls = []
        let businessLicenseUrls = []
        let authorizationUrls = []
        let customerIDUrls = []
        let mermorandumUrls = []
        let licenseUrls = []
        let ownerShipUrls = []
        let nar1Path1Urls = []
        let nnc1PathUrls = []
        let registrationUrls = []
        let incorporationUrls = []
        let parentUrls = []
        let businessRegUrls = []
        if (data.legalPersonCertificatePath && data.legalPersonCertificatePath[0]) {
          const path = await _oss.getOssFileUrl(data.legalPersonCertificatePath)
          if (path.data.succ) legalUrls = Object.values(path.data.data)
        }
        if (data.directorCertificatePath && data.directorCertificatePath[0]) {
          const path = await _oss.getOssFileUrl(data.directorCertificatePath)
          if (path.data.succ) legalUrls = legalUrls.concat(Object.values(path.data.data))
        }
        if (data.shareholderCertificatePath && data.shareholderCertificatePath[0]) {
          const path = await _oss.getOssFileUrl(data.shareholderCertificatePath)
          if (path.data.succ) shareholderUrls = Object.values(path.data.data)
        }
        if (data.businessLicensePath && data.businessLicensePath[0]) {
          const path = await _oss.getOssFileUrl(data.businessLicensePath)
          if (path.data.succ) businessLicenseUrls = Object.values(path.data.data)
        }
        if (data.authorizationPath && data.authorizationPath[0]) {
          const path = await _oss.getOssFileUrl(data.authorizationPath)
          if (path.data.succ) authorizationUrls = Object.values(path.data.data)
        }
        if (data.customerIDPath && data.customerIDPath[0]) {
          const path = await _oss.getOssFileUrl(data.customerIDPath)
          if (path.data.succ) customerIDUrls = Object.values(path.data.data)
        }
        if (data.mermorandumPath && data.mermorandumPath[0]) {
          const path = await _oss.getOssFileUrl(data.mermorandumPath)
          if (path.data.succ) mermorandumUrls = Object.values(path.data.data)
        }
        if (data.licensePathOrProofPaymentPath && data.licensePathOrProofPaymentPath[0]) {
          const path = await _oss.getOssFileUrl(data.licensePathOrProofPaymentPath)
          if (path.data.succ) licenseUrls = Object.values(path.data.data)
        }
        if (data.ownerShipPath && data.ownerShipPath[0]) {
          const path = await _oss.getOssFileUrl(data.ownerShipPath)
          if (path.data.succ) ownerShipUrls = Object.values(path.data.data)
        }
        if (data.nar1Path1 && data.nar1Path1[0]) {
          const path = await _oss.getOssFileUrl(data.nar1Path1)
          if (path.data.succ) nar1Path1Urls = Object.values(path.data.data)
        }
        if (data.nnc1Path && data.nnc1Path[0]) {
          const path = await _oss.getOssFileUrl(data.nnc1Path)
          if (path.data.succ) nnc1PathUrls = Object.values(path.data.data)
        }
        if (data.businessRegistrationCertificatePath && data.businessRegistrationCertificatePath[0]) {
          const path = await _oss.getOssFileUrl(data.businessRegistrationCertificatePath)
          if (path.data.succ) registrationUrls = Object.values(path.data.data)
        }
        if (data.incorporationPath && data.incorporationPath[0]) {
          const path = await _oss.getOssFileUrl(data.incorporationPath)
          if (path.data.succ) incorporationUrls = Object.values(path.data.data)
        }
        if (data.parentCertificatePath && data.parentCertificatePath[0]) {
          const path = await _oss.getOssFileUrl(data.parentCertificatePath)
          if (path.data.succ) parentUrls = Object.values(path.data.data)
        }
        if (data.businessRegistrationFilePath && data.businessRegistrationFilePath[0]) {
          const path = await _oss.getOssFileUrl(data.businessRegistrationFilePath)
          if (path.data.succ) businessRegUrls = Object.values(path.data.data)
        }
        state.registerInfo = Object.assign({}, data, {
          legalUrls,
          shareholderUrls,
          businessLicenseUrls,
          authorizationUrls,
          customerIDUrls,
          mermorandumUrls,
          licenseUrls,
          ownerShipUrls,
          nar1Path1Urls,
          nnc1PathUrls,
          registrationUrls,
          incorporationUrls,
          parentUrls,
          businessRegUrls,
          isResides: getTradeStatusName(data.isResides, 'isResides'),
          customerIdentity: getArrayStatusName(data.customerIdentity, 'customerIdentity'),
          sourceFunds: getArrayStatusName(data.sourceFunds, 'sourceFunds'),
          paymentPurpose: getArrayStatusName(data.paymentPurpose, 'paymentPurpose'),
          isRiskCountryTransaction: getArrayStatusName(data.isRiskCountryTransaction, 'isResides'),
          riskTransactionCountry: getArrayStatusName(data.riskTransactionCountry, 'riskTransactionCountry'),
          isParentCertificate: getTradeStatusName(data.isParentCertificate, 'isResides')
        })
      }
    }

    checkMobile()
    loadChildrenMenu()
    loadUpdateMap()
    loadSysLanguage()
    loadPhoneAreaCode()
    loadAcctRegisterInfo()

    return {
      phoneRef,
      getUsedCheck,
      phoneUpdate,
      sendCode,
      openUrl,
      provinceUpdate,
      cityUpdate,
      postalCodeUpdate,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
#userMaterials {
  .common-card-body {
    padding: 24px 36px;
    text-align: left;
    .info-area {
      margin-bottom: 48px;
      .title {
        font-size: 16px;
        font-weight: 500;
      }
      .body {
        margin-top: 24px;
        .user-form {
          margin-left: 64px;
          .info-input {
            width: 300px;
            border: 1px solid #d9d9d9;
            height: 32px;
            line-height: 32px;
            border-radius: 50px;
            padding-left: 24px;
            .ic-edit {
              margin: 0 12px 0 auto;
              color: @main-color;
              cursor: pointer;
            }
          }
          .info-div {
            width: 300px;
            padding-left: 24px;
          }
          .info-img {
            width: 300px;
            padding-left: 24px;
            overflow-x: scroll;
            white-space: nowrap;
          }
          .info-img:deep(.ant-image) {
            margin-right: 12px;
          }
        }
        .user-form:deep(.ant-form-item-label) {
          white-space: normal;
          overflow: inherit;
        }
      }
    }
  }
}
</style>

